<template>
  <div
    class="bg_col_fff bor_rad_8 box_sha_0_0_8_8_black_80_per p_16 m_0_16 m_t_16"
  >
    <div class="dis_flex ali_it_cen">
      <div class="flex_1 font_16 font_bold">统计</div>

      <!-- 日期筛选 -->
      <!-- DEPRECATED 3.3.9.7 -->
      <!-- <div
        class="dis_flex ali_it_cen"
        @click="filterForm = { ...filterForm, dateDialogShow: true }"
      >
        <img
          class="w_16 h_16 dis_flex"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/TsvtFIIYzRzkBuSudCrx.png"
        />

        <div class="font_14 col_2396F5 m_l_4">
          {{ filterForm.timeStr || '日期选择' }}
        </div> -->

      <!--  -->
      <!-- </div> -->

      <!-- 日期筛选 弹窗 -->
      <!-- DEPRECATED 3.3.9.7 -->
      <!-- <van-popup v-model="filterForm.dateDialogShow" position="bottom">
        <van-datetime-picker
          v-model="filterForm.time"
          type="date"
          title="选择年月日"
          @confirm="
            (v) => {
              filterForm = {
                ...filterForm,
                time: v,
                timeStr: moment(v).format('YYYY年MM月DD日'),
                timeStart: moment(v).format('YYYY-MM-DD 00:00:00'),
                timeEnd: moment(v).format('YYYY-MM-DD 23:59:59'),
                dateDialogShow: false,
              };
              getLoanGeneralStatistics();
            }
          "
          @cancel="filterForm = { ...filterForm, dateDialogShow: false }"
        /> -->

      <!--  -->
      <!-- </van-popup> -->

      <!-- 日期选择 -->
      <div class="dis_flex ali_it_cen">
        <div
          class="dis_flex ali_it_cen"
          @click="
            filterForm = {
              ...filterForm,
              tempTimeStart: filterForm.tempTimeStart || new Date(),
              timeStep: 0,
              timeDialogShow: true,
            }
          "
        >
          <img
            class="w_16 h_16 dis_flex"
            src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/TsvtFIIYzRzkBuSudCrx.png"
          />

          <div class="font_12 col_2396F5 m_l_4">
            {{ filterForm.timeStartStr || '开始日期' }}
          </div>

          <div class="font_12 m_0_8">-</div>

          <div class="font_12 col_2396F5">
            {{ filterForm.timeEndStr || '结束日期' }}
          </div>

          <!--  -->
        </div>

        <!-- 清除 -->
        <img
          v-show="filterForm.timeStartStr"
          class="w_16 h_16 dis_flex m_l_4"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220705/sYOxRslRIFJplIZHjJAB.png"
          @click="
            filterForm = {};
            getLoanGeneralStatistics();
          "
        />

        <!--  -->
      </div>

      <!-- 日期选择 弹窗 -->
      <van-popup v-model="filterForm.timeDialogShow" position="bottom">
        <van-datetime-picker
          v-if="filterForm.timeStep == 0"
          v-model="filterForm.tempTimeStart"
          type="date"
          title="开始日期"
          key="开始日期"
          @confirm="
            filterForm = {
              ...filterForm,
              timeStep: 1,
            }
          "
          @cancel="filterForm = { ...filterForm, timeDialogShow: false }"
        />

        <van-datetime-picker
          v-if="filterForm.timeStep == 1"
          v-model="filterForm.tempTimeEnd"
          type="date"
          title="结束日期"
          key="结束日期"
          :min-date="filterForm.tempTimeStart"
          @confirm="
            (v) => {
              filterForm = {
                ...filterForm,
                timeStart: moment(filterForm.tempTimeStart).format(
                  'YYYY-MM-DD 00:00:00'
                ),
                timeStartStr: moment(filterForm.tempTimeStart).format(
                  'YYYY-MM-DD'
                ),
                timeEnd: moment(v).format('YYYY-MM-DD 23:59:59'),
                timeEndStr: moment(v).format('YYYY-MM-DD'),
                timeDialogShow: false,
              };
              getLoanGeneralStatistics();
            }
          "
          @cancel="filterForm = { ...filterForm, timeStep: 0 }"
        />

        <!--  -->
      </van-popup>

      <!--  -->
    </div>

    <!-- 项 -->
    <div
      v-for="(item, index) in [
        {
          iconUrl:
            'http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/QtNRiTTOWiElXMZAAXpF.png',
          column0: {
            label: '申请进件（万元）',
            value: columnAmount((form.applyAmount || 0) / 10000),
          },
          column1: {
            label: '申请进件（笔）',
            value: columnAmount(form.applyCount, { fixed: 0 }),
          },
        },

        {
          iconUrl:
            'http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/phAMxpXGGovmiHgpeEYq.png',
          column0: {
            label: '预审通过（万元）',
            value: columnAmount((form.prequalifiedAmount || 0) / 10000),
          },
          column1: {
            label: '预审通过（笔）',
            value: columnAmount(form.prequalifiedCount, { fixed: 0 }),
          },
        },

        {
          iconUrl:
            'http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/PuIUkZEuaVBXDnKaShme.png',
          column0: {
            label: '额度批复（万元）',
            value: columnAmount((form.approvaledAmount || 0) / 10000),
          },
          column1: {
            label: '额度批复（笔）',
            value: columnAmount(form.approvaledCount, { fixed: 0 }),
          },
        },

        {
          iconUrl:
            'http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/hsIUOfHFYZtzkHNLffMm.png',
          column0: {
            label: '放款成功（万元）',
            value: columnAmount((form.loanedAmount || 0) / 10000),
          },
          column1: {
            label: '放款成功（笔）',
            value: columnAmount(form.loanedCount, { fixed: 0 }),
          },
        },

        //
      ]"
      :key="index"
      class="dis_flex ali_it_cen m_t_16"
    >
      <!-- 图标 -->
      <div class="bor_rad_4 box_sha_0_0_8_8_black_80_per p_8">
        <img class="w_24 h_24 dis_flex" :src="item.iconUrl" />
      </div>

      <div class="flex_1 m_l_16">
        <div class="font_16 font_bold">{{ item.column0.value }}</div>
        <div class="font_12 col_666 m_t_4">{{ item.column0.label }}</div>
      </div>

      <div class="flex_1">
        <div class="font_16 font_bold">{{ item.column1.value }}</div>
        <div class="font_12 col_666 m_t_4">{{ item.column1.label }}</div>
      </div>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>

<script>
const moment = window.moment;
import { columnAmount } from '@/utils/tools';

import { brokerDashboardLoanGeneralStatisticsPost } from '@/api/index';

const defaultFilterForm = () => {
  return {
    time: new Date(),
    timeStr: moment().format('YYYY年MM月DD日'),
    timeStart: moment().format('YYYY-MM-DD 00:00:00'),
    timeStartStr: moment().format('YYYY-MM-DD'),
    timeEnd: moment().format('YYYY-MM-DD 23:59:59'),
    timeEndStr: moment().format('YYYY-MM-DD'),
  };
};

export default {
  components: {},
  data() {
    return {
      filterForm: { ...defaultFilterForm() },
      form: {},

      //
    };
  },
  computed: {},
  mounted() {
    this.getLoanGeneralStatistics();

    //
  },
  methods: {
    moment,
    columnAmount,

    // 获取 进件概况统计
    async getLoanGeneralStatistics() {
      this.form = await brokerDashboardLoanGeneralStatisticsPost({
        ...this.filterForm,
      });
    },

    //
  },
};
</script>
